// React
import { useEffect } from "react";
import { useThemeContext } from "../../Contexts/ThemeContext";

// Stylings
import "./Verifying.scss";

type VerifyingProps = {
	isChecked: boolean;
	setIsVerified: (isVerified: boolean) => void;
};

const Verifying = (props: VerifyingProps): JSX.Element => {
	const { primaryColor } = useThemeContext();

	useEffect(() => {
		if (props.isChecked) {
			setTimeout(() => {
				props.setIsVerified(true);
			}, 2000);
		}
	}, [ props.isChecked ]);

	return (
		<div className="auth-container">
			<div className="text-container">
				<h1 style={{
					backgroundImage: `linear-gradient(to right, ${primaryColor} 50%, #ffff 50%)`
				}} >Authenticating . . .</h1>
			</div>
			<div className="auth-loading__container">
				<div style={{
					backgroundColor: primaryColor
				}}></div>
			</div>
		</div>
	);
};

export default Verifying;

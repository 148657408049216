import { Dispatch, SetStateAction, useCallback, useState } from "react";
import { CloseButton } from "../../Buttons";
import { Modal } from "../../../../Contexts/Modals";
import { HostInfo, JukeboxQueueInfo } from "../../../../../types/api";
import { useSpotifyAuthContext } from "../../../../Contexts/SpotifyAuthContext";
import { useThemeContext } from "../../../../Contexts/ThemeContext";
import { useSpotifyControlContext } from "../../../../Contexts/SpotifyControlContext";

// Stylings
import "./TokenModal.scss";
import { Box, Button, Typography } from "@mui/material";
import { useSnackbar } from "notistack";

type TokenModalProps = {
	hostInfo: HostInfo;
	jukeBoxSong: SpotifyApi.TrackObjectFull;
	jukeboxTokens?: string[];
	setJukeboxTokens: Dispatch<SetStateAction<string[]>>;
	setJukeBoxSong: (jukeBoxSong: SpotifyApi.TrackObjectFull | undefined) => void;
	setShowModal: (showModal: boolean) => void;
};

const TokenModal = (props: TokenModalProps): JSX.Element => {
	const [ fadeOut, setFadeOut ] = useState<string>("");
	const [ success, setSuccess ] = useState<string>("na");
	const { spotifyApi }	= useSpotifyAuthContext();
	const { enqueueSnackbar } = useSnackbar();

	const { socket, hostConnectionId } = useSpotifyControlContext();
	const { spotifyProfile } = useSpotifyAuthContext();
	const { primaryColor } = useThemeContext();

	const onApprove = useCallback(async () => {

		if(!props.jukeboxTokens || props.jukeboxTokens.length == 0){
			enqueueSnackbar("No jukebox tokens available", {
				variant: "error",
			});
			setSuccess("false");
		}

		const body: JukeboxQueueInfo = {
			hostConnectionId: props.hostInfo.hostConnectionId,
			timeQueued: "",
			hostInfo: props.hostInfo,
			requester: {
				spotifyId: spotifyProfile?.id ?? "",
				spotifyDisplayName: spotifyProfile?.display_name ?? "" 
			},
			songInfo: props.jukeBoxSong,
			origin: "guest",
			jukeboxToken: props.jukeboxTokens?.[ 0 ] ?? "",
			spotifyToken: spotifyApi.getAccessToken() ?? "",
		};

		const res = await fetch("/api/jukeboxQueue", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify(body),
		});

		props.setJukeboxTokens(prev => [ ...prev.filter(token => token != body.jukeboxToken) ]);

		if(res.status == 200) {

			const socketMessage = {
				action: "hostUpdate",
				hostConnectionId,
				spotifyToken: spotifyApi.getAccessToken(),
			};
			socket && socket.send(
				JSON.stringify(socketMessage),
			);

			setSuccess("true");
		} else {
			setSuccess("false");
		}


	}, [ socket, hostConnectionId, props.jukeboxTokens, props.setJukeboxTokens ]);

	const modalFadeOut = () => {
		setFadeOut("fade-out");
		setTimeout(() => {
			props.setShowModal(false);
			props.setJukeBoxSong(undefined);
		}, 400);
	};

	return (
		<Modal fadeOut={fadeOut}>
			{success === "na" && (
				<div className="token-modal__container">
					<CloseButton
						setShowModal={props.setShowModal}
						setFadeOut={setFadeOut}/>
					<Button onClick={onApprove}>Approve</Button>
				</div>
			)}
			{[ "true", "false" ].includes(success) && <div
				style={{
					width: "fit-content",
					display: "flex",
					flexDirection: "column",
					alignItems: "center",
					justifyContent: "center",
				}}
				className="token-response__container">
				<Box
					padding="8px"
					display="flex"
					flexDirection="column"
					justifyContent="center"
					alignItems="center">
					{success == "true" && (
						<>
							<Typography
								style={{ color: primaryColor }}>Success!</Typography>
							<Button
								fullWidth
								onClick={() => modalFadeOut()}>
								Return to Guest Page
							</Button>
						</>
					)}
					{success == "false" && (
						<>
							<Typography
								style={{ color: primaryColor }}>There was an issue</Typography>
							<Box
								display="flex"
								flexDirection="row"
								justifyContent="center"
								alignItems="center"
								padding="8px">
								<Button
									fullWidth
									onClick={() => modalFadeOut()}>
								Return to Guest Page
								</Button>
								
							</Box>
						</>
					)}
				</Box>
			</div>}
		</Modal>
	);
};

export default TokenModal;

import { QueueContextProvider } from "./QueueContext";
import { SpotifyControlContextProvider } from "./SpotifyControlContext";
import { ContextManager } from "./ContextManager";
import { SpotifyAuthContextProvider } from "./SpotifyAuthContext";
import { SnackbarProvider } from "notistack";
import { ThemeContextProvider } from "./ThemeContext";
import { LayoutContextProvider } from "./LayoutContext";


import { FC } from "react";
import { ReactGAContextProvider } from "./ReactGAContext";

const Contexts: FC = ({ children }) => {
	return (
		<>
			<ReactGAContextProvider>
				<SnackbarProvider
					autoHideDuration={2000}
					anchorOrigin={{
						vertical: "bottom",
						horizontal: "right",
					}}
					variant="info"
				>
					<SpotifyAuthContextProvider>
						<ThemeContextProvider>
							<LayoutContextProvider>
								<SpotifyControlContextProvider>
									<QueueContextProvider>
										<ContextManager>
											{children}
										</ContextManager>
									</QueueContextProvider>
								</SpotifyControlContextProvider>
							</LayoutContextProvider>
						</ThemeContextProvider>
					</SpotifyAuthContextProvider>
				</SnackbarProvider>
			</ReactGAContextProvider>
		</>
	);
};

export default Contexts;

import { Dispatch, SetStateAction, useEffect, useState } from "react";
import { MediaControls, SearchBar, SongCards } from "../../Global";
import { QueueJukeBox } from "../Queue";
import { useSpotifyControlContext } from "../../../Contexts/SpotifyControlContext";
import { useSpotifyAuthContext } from "../../../Contexts/SpotifyAuthContext";
import { TokenModal } from "../../Global/Modals";
import "./JukeOnGuest.scss";

type JukeOnGuestProps = {
	position: number;
	jukeboxTokens: string[];
	setJukeboxTokens: Dispatch<SetStateAction<string[]>>;
};

const JukeOnGuest = (props: JukeOnGuestProps): JSX.Element => {
	const { spotifyApi } = useSpotifyAuthContext();
	const { playerState, guestCommands, hostInfo } = useSpotifyControlContext();
	const [ searchStarted, setSearchStarted ] = useState<boolean>(false);
	const [ songSearchResult, setSongSearchResult ] = useState<SpotifyApi.TrackObjectFull[]>([]);
	const [ searchValue, setSearchValue ] = useState<string>("");
	const [ jukeBoxSong, setJukeBoxSong ] = useState<SpotifyApi.TrackObjectFull | undefined>();
	const [ showTokenModal, setShowTokenModal ] = useState<boolean>(false);

	useEffect(() => {
		const characters = "abcdefghijklmnopqrstuvwxyz0123456789";

		const random = Math.floor(Math.random() * characters.length);

		const searchSongStart = async () => {
			await spotifyApi
				.searchTracks(characters.slice(random, random + 1), {
					limit: 20 
				})
				.then((res) => {
					if (res.statusCode == 200 && res.body.tracks)
						setSongSearchResult(
							res.body.tracks.items.filter((track) => {
								return track.explicit != true;
							}),
						);
				});
		};

		if (hostInfo) searchSongStart();
	}, [ hostInfo ]);

	useEffect(() => {
		spotifyApi.searchTracks(searchValue, {
			limit: 20 
		}).then((res) => {
			if (res.statusCode == 200 && res.body.tracks && hostInfo)
				setSongSearchResult(
				    res.body.tracks.items.filter((track) => {
				        return hostInfo.explicitMusic ? true : track.explicit != true;
				    }),
				);
		});
	}, [ searchStarted ]);

	useEffect(() => {
		setShowTokenModal(!!hostInfo && !!jukeBoxSong);
	}, [ hostInfo, jukeBoxSong ]);

	return (
		<div className="guest-page__container--juke-on">
			{showTokenModal && (<TokenModal
				hostInfo={hostInfo!}
				jukeBoxSong={jukeBoxSong!}
				setJukeBoxSong={setJukeBoxSong}
				setShowModal={setShowTokenModal}
				jukeboxTokens={props.jukeboxTokens}
				setJukeboxTokens={props.setJukeboxTokens}
			/>
			)}
			<SearchBar 
				setSearchStarted={setSearchStarted} 
				searchValue={searchValue} 
				setSearchValue={setSearchValue}
			/>
			<div className="guest-page__middle-section">
				<SongCards
					searchStarted={searchStarted}
					songSearchResult={songSearchResult}
					setSelectedSong={setJukeBoxSong} />
				<QueueJukeBox
					style={"guest"}
					jukeboxTokens={props.jukeboxTokens.length}
					jukebox={true}/>
			</div>
			{playerState && (
				<div className="media-player__container">
					<MediaControls
						playing={playerState.playing}
						volumeLevel={playerState.volume}
						setVolumeLevel={guestCommands.volume}
						songLength={playerState.songLength / 1000}
						songCurrentTime={props.position}
						songCurrentInfo={{
							albumArt: playerState.coverArt,
							artist: playerState.artist,
							title: playerState.title,
							songLink: playerState.songLink,
							artistLink: playerState.artistLink
						}}
						style={"guest-bar"}
					/>
				</div>
			)}
		</div>
	);
};
export default JukeOnGuest;

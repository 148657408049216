export const commaFunction = (array: SpotifyApi.ArtistObjectSimplified[]) => {
	return array.length > 1 ? array.reduce(
		(all: string, artist: SpotifyApi.ArtistObjectSimplified, i) => {
			if (i === array.length -1) {
				return `${all}, ${artist.name}`;
			} else if (i === 0) {
				return artist.name;
			} else {
				return `${artist.name}, ${all}`;
			}
		},
		""
	) : array[ 0 ].name;
};




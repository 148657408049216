import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useSpotifyAuthContext } from "../../../Contexts/SpotifyAuthContext";
import "./ErrorPage.scss";

const ErrorPage = () => {
	const navigate = useNavigate(); 
	const { spotifyLoggedIn } = useSpotifyAuthContext();
	const [ timer, setTimer ] = useState<number>(5);
    
	useEffect(() => {
		const interval = setInterval(() => {
			setTimer(timer -1);
		}, 1000);
		return () => clearInterval(interval);
	}, [ timer ]);
    
	useEffect(() => {
		const timeoutVar = setTimeout(() => {
			spotifyLoggedIn ? navigate("/home") : navigate("/");
		}, 5000);
		return () => { clearTimeout(timeoutVar);};
	},[ spotifyLoggedIn ]);

	return (
		<div className="error-page__container">
			<h1>That wasn't right . . .</h1>
			<h1>Redirecting in {timer}</h1>
		</div>
	);
};

export default ErrorPage;
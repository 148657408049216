// React
import { useState } from "react";

// Components
import { Queue } from "../Queue";
import { useThemeContext } from "../../../Contexts/ThemeContext";


// MUI Icons
import { Close, VolumeMute } from "@mui/icons-material";
import { useSpotifyControlContext } from "../../../Contexts/SpotifyControlContext";

// Images
import SpotifyLogo from "../../Global/Images/Spotify_Logo_RGB_Green.png";

// Styling
import "./JukeOffGuest.scss";

type JukeOffGuestProps = {
    position: number;
};

const JukeOffGuest = (props: JukeOffGuestProps): JSX.Element => {
	const { playerState, guestCommands } = useSpotifyControlContext();
	const { primaryColor } = useThemeContext();

	const [ localVolume, setLocalVolume ] = useState<number>(50);
	const [ muteButton, setMuteButton ] = useState<string>("mute-button");
	const [ prevVolume, setPrevVolume ] = useState<number>(0);

	const muteFunction = () => {
		setPrevVolume(0);

		if (playerState) {
			if (localVolume > 0) {
				setPrevVolume(localVolume);
				guestCommands.volume(0);
				setLocalVolume(0);
			} else if (localVolume === 0 && prevVolume === 0) {
				guestCommands.volume(30);
				setLocalVolume(30);
			} else {
				guestCommands.volume(prevVolume);
				setLocalVolume(prevVolume);
			}
		}

		setMuteButton("mute-button-animate");
		setTimeout(() => {
			setMuteButton("mute-button");
		}, 500);
	};

	const timeFormatting = (t: number) => {
		t = Number(t);
		const m = Math.floor((t % 3600) / 60);
		const s = Math.floor((t % 3600) % 60);

		const sDisplay = s > 0 ? (s < 10 ? "0" + s : s) : "00";
		return m + ":" + sDisplay;
	};

	return (
		<>
			{playerState && (<div
				className="guest-page__container--juke-off"
				style={{
					backgroundImage: `url(${playerState.coverArt})`,
				}}>
				<div className="media-content__container">
					<div className="media-section__left">
						<div
							className="top-section"
							style={{
								backgroundColor: "#242424"
							}}>
							<div className="text-container">
								<div style={{
									backgroundImage: `linear-gradient(.25turn, ${primaryColor}, #CFD7C7, ${primaryColor})`
								}}>{playerState.playing ? "Now Playing" : "Paused by Host"}</div>
							</div>
							<div className="image-container">
								<img src={playerState.coverArt}></img>
							</div>
							<div className="song-info__container">
								<div className="spotify-logo__container">
									<img
										alt=""
										src={`${SpotifyLogo}`}></img>
								</div>
								<div className="song-info">
									<a
										href={playerState.songLink}
										target="_blank"
										className="song-name__link"
										rel="noreferrer">
										<p style={{
											color: primaryColor
										}}><span></span>{playerState.title}</p>
									</a>
									<a
										href={playerState.artistLink}
										target="_blank"
										className="artist-name__link"
										rel="noreferrer">
										<p><span></span>
											{playerState.artist}
											{/* Need to figure out how to play a song from this menu or add the search to the player. Probably move the search feature inside of the player component */}
										</p>
									</a >
								</div>
							</div>
						</div>
						<div className="bottom-section__container">
							<div className="bottom-section">
								<div className="left-section">
									<div className="media-bar__container">
										<div className="media-bar__time-container">
											<div className="song-time__current">
												{timeFormatting(props.position)}
											</div>
											<div className="song-length__container">
												<input
													className="song-slider"
													type="range"
													min="0"
													max={playerState.songLength / 1000}
													step="1"
													value={props.position}
													onChange={() => null}
												/>
												<label></label>
												<div
													className="song-progress"
													style={{
														width: `${(props.position /
                                                            (playerState.songLength /
                                                                1000)) *
                                                            100
														}%`,
													}}
												></div>
											</div>
											<div className="song-time__max">
												{!isNaN(playerState.songLength / 1000)
													? timeFormatting(
														playerState.songLength / 1000,
													)
													: "0:00"}
											</div>
										</div>
									</div>
								</div>
								<div className="right-section">
									<div className="volume-position">
										<div
											className="mute-button__container"
											onClick={() => muteFunction()}
										>
											<VolumeMute
												className="volume-icon"
												style={{
													color: primaryColor
												}}
											/>
											<div
												className="sound-wave__one"
												style={{
													opacity: `${localVolume === 0
														? 0
														: localVolume / 50 + 0.1
													}`,
												}}
											></div>
											<div
												className="sound-wave__two"
												style={{
													opacity: `${localVolume <= 45
														? 0
														: (localVolume - 45) / 55 + 0.2
													}`,
												}}
											></div>
											<Close
												className={`x-icon ${localVolume === 0 ? "show" : "dont-show"
												}`}
											/>
											<div
												className={`mute-button__background ${muteButton}`}
											></div>
										</div>
									</div>
									<div className="volume-slider">
										<div className="slider-container">
											<label></label>
											<input
												className="slider"
												type="range"
												min="0"
												max="100"
												step="1"
												value={localVolume}
												onChange={(e) => {
													setLocalVolume(e.target.valueAsNumber);
												}}
												onMouseUp={() =>
													guestCommands.volume(localVolume)
												}
												onTouchEnd={() =>
													guestCommands.volume(localVolume)
												}
											/>
											<div
												className="progress-bar"
												style={{
													width: `${localVolume}%`,
													backgroundImage: `linear-gradient(.25turn, ${primaryColor}, #CFD7C7)`
												}}

											></div>
										</div>
									</div>
								</div>
							</div>
						</div>
					</div>
					<div className="song-queue__container">
						<div className="queue-header">
							<div style={{
								backgroundImage: `linear-gradient(.25turn, ${primaryColor}, #CFD7C7, ${primaryColor})`
							}}>Song Queue</div>
						</div>
						<Queue />
						Hi
					</div>
				</div>
			</div>)}
		</>
	);
};
export default JukeOffGuest;

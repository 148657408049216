const regionIntensity = (
	levels: Uint8Array,
	numRegions: number,
	regions: [number, number],
): number => {
	let rms = 0;
	if (
		regions[ 0 ] > numRegions ||
		regions[ 1 ] > numRegions ||
		regions[ 0 ] < 0 ||
		regions[ 1 ] < 1 ||
		regions[ 0 ] >= regions[ 1 ]
	)
		throw new Error("Region is out of bounds.");
	if (numRegions < 1 || numRegions > levels.length) throw new Error("Invalid number of regions");
	const segmentLength = levels.length / numRegions;
	for (let i = regions[ 0 ] * segmentLength; i < regions[ 1 ] * segmentLength; i++) {
		rms += levels[ i ] * levels[ i ];
	}
	rms /= segmentLength * (regions[ 1 ] - regions[ 0 ]);
	rms = Math.sqrt(rms);
	return rms;
};

export default regionIntensity;

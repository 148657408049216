// React
import { useState } from "react";
import { CloseButton } from "../../Buttons";
import { Modal } from "../../../../Contexts/Modals";
import { useThemeContext } from "../../../../Contexts/ThemeContext";

// Stylings
import "./ThemeModal.scss";

type ThemeModalProps = {
	setShowModal: (showThemeModal: boolean) => void;
};

const ThemeModal = (props: ThemeModalProps): JSX.Element => {
	const { primaryColor, setPrimaryColor } = useThemeContext();
	const [ fadeOut, setFadeOut ] = useState<string>("");

	return (
		<Modal fadeOut={fadeOut}>
			<div className="theme-modal__container">
				<CloseButton
					setShowModal={props.setShowModal}
					setFadeOut={setFadeOut}/>
				<div className="theme-modal__text--container">
					<h2 style={{
						color: primaryColor
					}}>Theme Picker</h2>
					<p>Change the theme to your favorite color to customize the site the way you want it.</p>
				</div>
				<div className="theme-modal__color--container">
					<div onClick={() => setPrimaryColor("#FB9160")}></div>
					<div onClick={() => setPrimaryColor("#3EB489")}></div>
					<div onClick={() => setPrimaryColor("#AB87FF")}></div>
					<div onClick={() => setPrimaryColor("#999999")}></div>
					<div onClick={() => setPrimaryColor("#ff7420")}></div>
					<div onClick={() => setPrimaryColor("#106344")}></div>
					<div onClick={() => setPrimaryColor("#e25098")}></div>
					<div onClick={() => setPrimaryColor("#0496FF")}></div>
					<div onClick={() => setPrimaryColor("#e4ba14")}></div>
					<div onClick={() => setPrimaryColor("#228B22")}></div>
					<div onClick={() => setPrimaryColor("#8A1C7C")}></div>
					<div onClick={() => setPrimaryColor("#C1292E")}></div>
				</div>
			</div>
		</Modal>
	);
};

export default ThemeModal;

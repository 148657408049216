// React
import { useCallback, useEffect, useRef, useState } from "react";

// Components
import MediaControls from "../Global/MediaControls/MediaControls";

// Contexts
import { useGaContext } from "../../Contexts/ReactGAContext";
import { useThemeContext } from "../../Contexts/ThemeContext";

// Utils
import map from "../../Utils/map";
import regionIntensity from "../../Utils/regionIntensity";
import tracks, { SongInfo } from "../../Resources/Music/Tracks";

// Styling
import "./Visualizer.scss";

const Visualizer = (): JSX.Element => {

	// Contexts
	const { sendGAEvent } = useGaContext();
	const { primaryColor } = useThemeContext();

	const startingPosition = 85;
	const isWindows = window.navigator.platform.toLowerCase().includes("win");
	const numLevels = 32768 / 8;
	// const numLevels = 1024
	const audioRef = useRef<any>(null);

	// State
	const [ playing, setPlaying ] = useState<boolean>(false);
	const [ refsSet, setRefsSet ] = useState<boolean>(false);
	const [ refsActuallySet, setRefsActuallySet ] = useState<boolean>(false);
	const [ analyzer, setAnalyzer ] = useState<AnalyserNode>();
	const [ audioContext, setAudioContext ] = useState<AudioContext>();
	const [ src, setSrc ] = useState<MediaElementAudioSourceNode>();
	const [ clipVals, setClipVals ] = useState<string>(
		`0% 100%, 0% ${startingPosition}%, 100% ${startingPosition}%, 100% 100%`,
	);
	const [ intensityVal, setIntensityVal ] = useState<number>(0.4);
	const [ skipBack, setSkipBack ] = useState<boolean>(false);
	const [ skipForward, setSkipForward ] = useState<boolean>(false);
	const [ currentSong, setCurrentSong ] = useState<number>(0);
	const [ snareIntensity, setSnareIntensity ] = useState<number>(0);
	const [ volumeLevel, setVolumeLevel ] = useState<number>(50);
	const [ songLength, setSongLength ] = useState<any>(0);
	const [ songCurrentTime, setSongCurrentTime ] = useState<number>(0);
	const [ toggleTime, setToggleTime ] = useState<boolean>(true);
	const [ songCurrentInfo, setSongCurrentInfo ] = useState<SongInfo>(tracks[ 0 ]);

	let bufferLength: number;
	let clipString = "";
	let levels: Uint8Array;

	const clipValues = (levels: Uint8Array): string => {
		clipString = "0% 100%,";
		const mappedLevels = levels.map((val) =>
			map(Math.cbrt(val), 0, Math.cbrt(255), startingPosition, 0),
		);

		mappedLevels.forEach((y: number, i: number) => {
			const x = (i / mappedLevels.length) * (isWindows ? 110 : 100);
			clipString += ` ${x}% ${y}%,`;
			clipString += ` ${x + (1 / mappedLevels.length) * 100}% ${y}%,`;
		});
		clipString += "100% 100%";
		return clipString;
	};

	const volumeFunction = (audio: JSX.IntrinsicElements["audio"]) => {
		//@ts-ignore
		audio.volume = volumeLevel / 100;
	};

	const dragTime = (audio: JSX.IntrinsicElements["audio"], time: number) => {
		//@ts-ignore
		audio.currentTime = time;
	};

	const play = (audio: JSX.IntrinsicElements["audio"]) => {
		//@ts-ignore
		if (audio.paused && playing) {
			//@ts-ignore
			audio.play();
			//@ts-ignore
		} else if (!audio.paused && !playing) {
			//@ts-ignore
			audio.pause();
			//@ts-ignore
		} else if (!audio.paused == playing) {
			//NOOP
		}
	};

	const skip = (audio: JSX.IntrinsicElements["audio"]) => {
		setCurrentSong((prev) => (prev + 1) % tracks.length);
	};

	const back = (audio: JSX.IntrinsicElements["audio"]) => {
		setCurrentSong((prev) => (prev == 0 ? tracks.length - 1 : prev - 1));
	};

	const change = (audio: JSX.IntrinsicElements["audio"]) => {
		audio.src = tracks[ currentSong ].song;
		//@ts-ignore
		setSongLength(audio.duration);
		//@ts-ignore
		audio.load();
		setPlaying(true);
		play(audio);
	};

	const renderFrame = () => {
		requestAnimationFrame(renderFrame);
		bufferLength = analyzer!.frequencyBinCount;
		levels = new Uint8Array(bufferLength);
		analyzer!.getByteFrequencyData(levels);
		setClipVals(clipValues(levels));
		setIntensityVal(regionIntensity(levels, 512, [ 0, 2 ]));
		setSnareIntensity(regionIntensity(levels, 16, [ 12, 14 ]));
		setToggleTime((prev) => !prev);
	};

	useEffect(() => {
		if (audioRef.current) {
			audioRef.current.src = tracks[ currentSong ].song;
			audioRef.current.load();
			audioRef.current.muted = false;
			audioRef.current.pause();
			audioRef.current.addEventListener("ended", (e: Event) => skip(audioRef.current));
			audioRef.current.addEventListener("loadedmetadata", (e: Event) =>
			//@ts-ignore
				setSongLength(e.srcElement!.duration),
			);
			setRefsActuallySet(true);
			setAudioContext(new AudioContext());
		}
	}, [ audioRef, refsSet ]);

	useEffect(() => {
		if (audioContext) {
			setSrc(audioContext.createMediaElementSource(audioRef.current));
			setAnalyzer(audioContext.createAnalyser());
		}
	}, [ audioContext ]);

	useEffect(() => {
		if (src && analyzer) {
			src.connect(analyzer);
			analyzer.connect(audioContext!.destination);
			analyzer.fftSize = numLevels;
			renderFrame();
		}
	}, [ src, analyzer ]);

	useEffect(() => {
		if (audioRef.current) play(audioRef.current);
	}, [ playing ]);

	useEffect(() => {
		if (audioRef.current) setSongCurrentTime(audioRef.current.currentTime);
	}, [ toggleTime ]);

	useEffect(() => {
		if (audioRef.current) volumeFunction(audioRef.current);
	}, [ volumeLevel ]);

	useEffect(() => {
		if (audioRef.current) skip(audioRef.current);
	}, [ skipBack ]);

	useEffect(() => {
		if (audioRef.current) back(audioRef.current);
	}, [ skipForward ]);

	useEffect(() => {
		if (audioRef.current) change(audioRef.current);
		setSongCurrentInfo(tracks[ currentSong ]);
	}, [ currentSong ]);

	const divClick = () => {
		setRefsSet((prev) => (prev ? prev : true));
	};

	const skipBackFunction = () => {
		setSkipBack((prev: boolean) => !prev);
	};

	const skipForwardFunction = () => {
		setSkipForward((prev: boolean) => !prev);
	};

	const setPlayingFunction = () => {
		setPlaying((prev: boolean) => !prev);
	};

	const dragTimeFunction = useCallback(
		(time: number) => {
			if (audioRef.current) dragTime(audioRef.current, time);
		},
		[ audioRef ],
	);

	return (
		<div
			className="visualizer-container"
			onClick={divClick}>
			<div
				className="visualizer-background__filter"
				style={{
					backgroundColor: `rgba(0, 0, 0, ${map(intensityVal, 0, 255, .45, 0.35)})`,
				}}
			></div>
			{/* // These components only load after the user clicks something */}
			{refsSet && <audio ref={audioRef}></audio>}
			<div className="splash-container">
				<div className="splash-header__container">
					<div>
						<h1
							style={{
								transform: `rotate(${
									snareIntensity > 10
										? ((snareIntensity - 10) / 245) *
										  (Math.round(snareIntensity) % 2 == 0 ? -2 : 2)
										: 0
								}deg)`,
								WebkitTextStrokeColor: primaryColor,
							}}
						>
							STREAMSYNC
							<p style={{
								clipPath: `polygon(${clipVals})`,
								backgroundImage: `linear-gradient(.25turn, ${primaryColor}, #CFD7C7)` 
							}}>STREAMSYNC</p>
						</h1>
					</div>
				</div>
				<div
					className="media-controls-container"
					onClick={(e) => sendGAEvent("misc", "SplashMediaBar")}
				>
					<MediaControls
						playing={playing}
						setPlaying={setPlayingFunction}
						setSongCurrentTime={dragTimeFunction}
						setVolumeLevel={setVolumeLevel}
						skipBack={skipBackFunction}
						skipForward={skipForwardFunction}
						songCurrentInfo={songCurrentInfo}
						songCurrentTime={songCurrentTime}
						songLength={songLength}
						style={"splash-bar"}
						volumeLevel={volumeLevel}
					/>
				</div>
			</div>
		</div>
	);
};

export default Visualizer;

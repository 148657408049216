// React
import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

// Types
import { HostFrontend, HostList } from "../../../../types/frontend";

// Images
import SpotifyLogo from "../../Global/Images/Spotify_Logo_RGB_Green.png";

// Contexts
import { useThemeContext } from "../../../Contexts/ThemeContext";
import { useSpotifyAuthContext } from "../../../Contexts/SpotifyAuthContext";

// Components
import { SearchBar } from "../../Global";
import { LoginModal } from "../../Global/Modals";

// Stylings
import "./HomePage.scss";

const HomePageComponent = (): JSX.Element => {
	const navigate = useNavigate();
	const { primaryColor } = useThemeContext();
	const { spotifyLoggedIn } = useSpotifyAuthContext();
	const cardContainer = useRef<HTMLDivElement>(null);

	const [ hosts, setHosts ] = useState<HostList>();
	const [ searchStarted, setSearchStarted ] = useState<boolean>(false);
	const [ containerSize, setContainerSize ] = useState<string>("");
	const [ scroller, setScroller ] = useState<string>("");
	const [ searchValue, setSearchValue ] = useState<string>("");
	const [ showLoginModal, setShowLoginModal ] = useState<boolean>(false);

	const joinSync = (host: HostFrontend) => {
		if(!spotifyLoggedIn){
			setShowLoginModal(true);
		} else {
			navigate(`/guest/${host.hostConnectionId}`);
		}
	};

	useEffect(() => {
		const getCurrentHosts = async () => {
			const responseHosts = await fetch("/api/listHosts");
			const parsedHosts = await responseHosts.json();
			setHosts(parsedHosts.hosts);
		};

		getCurrentHosts();
		// add refresh button
	}, []);

	useEffect(() => {
		if(window.localStorage.getItem("from") == "logout"){
			navigate("/");
		}
	}, []);

	useEffect(() => {
		if (hosts && searchValue && searchStarted) {
			setHosts((prev) => {
				const sortedHosts = [ ...(prev ?? []) ];
				sortedHosts.sort((a: HostFrontend, b: HostFrontend) => {
					const aString = a.hostSpotifyDisplayName;
					const bString = b.hostSpotifyDisplayName;
					const aMatch = searchValue.split("").reduce((prev, curr) => {
						return aString.includes(curr) ? prev + 1 : prev;
					}, 0);
					const bMatch = searchValue.split("").reduce((prev, curr) => {
						return bString.includes(curr) ? prev + 1 : prev;
					}, 0);
					return bMatch - aMatch;
				});
				return sortedHosts;
			});
		}

		if (searchStarted && cardContainer.current) {
			cardContainer.current.scroll({
				top: 0,
				behavior: "smooth" 
			});
		}
	}, [ searchStarted ]);

	useEffect(() => {
		if (hosts) {
			if (hosts.length <= 5) {
				setContainerSize("");
				setScroller("");
			} else if (hosts.length === 6) {
				setContainerSize("small");
				setScroller("");
			} else if (hosts.length > 6 &&  hosts.length < 9) {
				setContainerSize("medium");
				setScroller("");
			} else if (hosts.length === 9) {
				setScroller("");
			} else if(hosts.length === 10) {
				setContainerSize("");
				setScroller("");
			} else {
				setScroller("scroll");
			}
		}
	}, [ hosts ]);

	return (
		<div className="sync-page__container">
			{showLoginModal && <LoginModal setShowModal={setShowLoginModal} />}
			<SearchBar 
				setSearchStarted={setSearchStarted} 
				searchValue={searchValue} 
				setSearchValue={setSearchValue}
			/>
			<div className="active-sync__container" >
				{hosts && hosts.length > 0 ? <div
					ref={cardContainer}
					className={`sync-card__container ${containerSize} ${scroller}`}>
					{hosts &&
						hosts.map((host: any, index: number) => {
						    return (
						        <div
						            onClick={() => joinSync(host)}
						            key={`host-${index}`}
						            className={"sync-card"}
						        >
						            <div className="sync-card__top">
						                <img
						                    className="card-image__album-art"
						                    alt=""
						                    src={host.playerState.coverArt}
						                ></img>
						                <img
						                    className="card-image__spotify-logo"
						                    alt=""
						                    src={`${SpotifyLogo}`}
						                ></img>
						            </div>
						            <div className="sync-card__bottom">
						                <div className="host-name__container">
						                    <div className="host-name">
						                        {host.hostSpotifyDisplayName}
						                    </div>
						                </div>
						            </div>
						        </div>
						    );
						})}
				</div> :
					<div className="no-sync__container">
						<h1>No Syncs Active</h1>
						<button
							onClick={() => navigate("/host")}
							style={{
								backgroundColor: primaryColor
							}}>Start A Sync</button>
					</div>
				}
			</div>
		</div>
	);
};

export default HomePageComponent;

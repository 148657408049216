// React
import { useEffect, useState } from "react";

// Contexts
import { Modal } from "../../../../Contexts/Modals";
import { useThemeContext } from "../../../../Contexts/ThemeContext";

// MUI Icons
import { CheckCircleOutlined, Close, ErrorOutlineOutlined } from "@mui/icons-material";

// Packages
import { useSnackbar } from "notistack";
import validator from "validator";

// Components
import ToolTipRight from "../../ToolTips/ToolTipRight";
import ToolTipBottom from "../../ToolTips/ToolTipBottom";
import { CloseButton } from "../../Buttons";

// Stylings
import "./CollaborateModal.scss";

type CollaborateModalProps = {
	fromAbout: boolean;
	setFromAbout: (fromAbout: boolean) => void;
	setShowCollabModal: (showModal: boolean) => void;
};

const CollaborateModal = (props: CollaborateModalProps): JSX.Element => {
	const { enqueueSnackbar } = useSnackbar();
	const { primaryColor } = useThemeContext();

	const [ artistName, setArtistName ] = useState<string>("");
	const [ artistNameValidated, setArtistNameValidated ] = useState<string>("false");
	const [ emailAddress, setEmailAddress ] = useState<string>("");
	const [ emailAddressValidated, setEmailAddressValidated ] = useState<string>("false");
	const [ fadeOut, setFadeOut ] = useState<string>("");
	const [ firstName, setfirstName ] = useState<string>("");
	const [ firstNameValidated, setfirstNameValidated ] = useState<string>("false");
	const [ lastName, setLastName ] = useState<string>("");
	const [ lastNameValidated, setLastNameValidated ] = useState<string>("false");
	const [ validated, setValidated ] = useState<boolean>(false);
	const [ formFade, setFormFade ] = useState<string>("");

	const [ firstNameStarted, setFirstNameStarted ] = useState<string>("");
	const [ lastNameStarted, setLastNameStarted ] = useState<string>("");
	const [ emailStarted, setEmailStarted ] = useState<string>("");
	const [ artistStarted, setArtistStarted ] = useState<string>("");
	const [ buttonColor, setButtonColor ] = useState<string>("rgba(10, 10, 10, .4)");

	const validationArray = [
		artistNameValidated,
		emailAddressValidated,
		firstNameValidated,
		lastNameValidated,
	];

	useEffect(() => {
		const validateFields = () => {
			for (let i = 0; i < validationArray.length; i++) {
				if (validationArray[ i ] != "valid") {
					setValidated(false);
					setButtonColor("rgba(10, 10, 10, .4)");
					return;
				}
			}
			setValidated(true);
			setButtonColor(primaryColor);
		};
		validateFields();
	}, [ artistNameValidated, emailAddressValidated, firstNameValidated, lastNameValidated ]);

	const modalFadeOut = () => {
		props.setFromAbout(false);
		setFadeOut("fade-out");
		setTimeout(() => {
			props.setShowCollabModal(false);
			setFormFade("");
		}, 400);
	};

	const submitFunction = async (e: any) => {
		e.preventDefault();
		setValidated(false);
		setButtonColor("rgba(10, 10, 10, .4)");
		enqueueSnackbar("Sending...");
		setTimeout(() => {
			modalFadeOut();
		}, 1000);

		const res = await fetch("/api/collabSubmission", {
			method: "POST",
			headers: {
				"Content-Type": "application/json",
			},
			body: JSON.stringify({
				firstName,
				lastName,
				emailAddress,
				artistName,
			}),
		});

		if (res.status === 200) enqueueSnackbar("Sent!", {
			variant: "success"
		});

		setTimeout(() => {
			setFormFade("");
			props.setFromAbout(false);
		}, 1000);
	};

	const firstNameValidation = () => {
		if (validator.isLength(firstName, {
			min: 2,
			max: 15 
		}) && !validator.isURL(firstName)) {
			setFirstNameStarted("");
			setfirstNameValidated("valid");
		} else {
			setfirstNameValidated("not-valid");
		}
	};

	const checkFirstName = () => {
		if(firstNameValidated != "valid") {
			setFirstNameStarted("started");
			setfirstNameValidated("not-valid");
		} 
	};

	const lastNameValidation = () => {
		if (validator.isLength(lastName, {
			min: 2,
			max: 20 
		}) && !validator.isURL(lastName)) {
			setLastNameStarted("");
			setLastNameValidated("valid");
		} else {
			setLastNameValidated("not-valid");
		}
	};

	const checkLastName = () => {
		if(lastNameValidated != "valid") {
			setLastNameStarted("started");
			setLastNameValidated("not-valid");
		}
	};

	const validateEmail = () => {
		if (validator.isEmail(emailAddress)) {
			setEmailStarted("");
			setEmailAddressValidated("valid");
		} else {
			setEmailAddressValidated("not-valid");
		}
	};

	const checkEmail = () => {
		if(firstNameValidated != "valid") {
			setEmailStarted("started");
			setEmailAddressValidated("not-valid");
		} 
	};

	const artistNameValidation = () => {
		if (validator.isLength(artistName, {
			min: 2,
			max: 30 
		}) && !validator.isURL(artistName)) {
			setArtistStarted("");
			setArtistNameValidated("valid");
		} else {
			setArtistNameValidated("not-valid");
		}
	};

	const checkArtist = () => {
		if(firstNameValidated != "valid") {
			setArtistStarted("started");
			setArtistNameValidated("not-valid");
		} 
	};

	return (
		<Modal fadeOut={fadeOut}>
			<ToolTipRight
				id={"first-name__input"}
				message={"Must be between 2 and 15 characters"} />
			<ToolTipRight
				id={"last-name__input"}
				message={"Must be between 2 and 20 characters"} />
			<ToolTipRight
				id={"email__input"}
				message={"Please provide a valid email address"} />
			<ToolTipRight
				id={"artist-name__input"}
				message={"Must be between 2 and 30 characters"} />
			<div className="collab-form__container">
				<CloseButton
					setShowModal={props.setShowCollabModal}
					setFadeOut={setFadeOut}/>
				<div className="collab-form">
					{!props.fromAbout && <div className={`form-description__container ${formFade}`}>
						<div>
							<p>
								At Streamsync, we want to shed light on musicians and artists
								that are on the come up. We plan to create a place where you can
								check out new music from smaller artists that want to put their
								music out there. If you want to get your music featured on the
								site, fill out the form and we will be in touch.
							</p>
						</div>
						<div>
							<button
								style={{
									backgroundColor: primaryColor
								}}
								onClick={() => setFormFade("fade")}>Reach Out
							</button>
						</div>
					</div>}
					<form className={`${formFade}`}>
						<div>
							<input
								className={`${firstNameValidated}`}
								onKeyUp={() => firstNameValidation()}
								onBlur={() => checkFirstName()}
								autoComplete="new-password"
								name="firstName"
								type="text"
								value={firstName}
								onChange={(e) => setfirstName(e.target.value)}
								required={true}
								spellCheck="false"
								// tabIndex={-1}
							/>
							<label style={{
								color: firstNameValidated === "valid" ? primaryColor : ""
							}}>First Name</label>
							<ErrorOutlineOutlined
								tabIndex={-1}
								data-tip 
								data-for="first-name__input" 
								className={`required ${firstNameStarted}`}
							/>
							{firstNameValidated === "valid" && (
								<CheckCircleOutlined
									style={{
										color: primaryColor
									}}
									className="success" />
							)}
						</div>
						<div>
							<input
								className={`${lastNameValidated}`}
								onKeyUp={() => lastNameValidation()}
								onBlur={() => checkLastName()}
								autoComplete="new-password"
								name="lastName"
								type="text"
								value={lastName}
								onChange={(e) => setLastName(e.target.value)}
								required={true}
								spellCheck="false"
							/>
							<label style={{
								color: lastNameValidated === "valid" ? primaryColor : ""
							}}>Last Name</label>
							<ErrorOutlineOutlined
								tabIndex={-1}
								data-tip 
								data-for="last-name__input"
								className={`required ${lastNameStarted}`} 
							/>
							{lastNameValidated === "valid" && (
								<CheckCircleOutlined
									style={{
										color: primaryColor
									}}
									className="success" />
							)}
						</div>
						<div>
							<input
								className={`${emailAddressValidated}`}
								autoComplete="new-password"
								name="emailAddress"
								type="text"
								value={emailAddress}
								onKeyUp={() => validateEmail()}
								onBlur={() => checkEmail()}
								onChange={(e) => setEmailAddress(e.target.value)}
								required={true}
								spellCheck="false"
							/>
							<label style={{
								color: emailAddressValidated === "valid" ? primaryColor : ""
							}}>Email Address</label>
							<ErrorOutlineOutlined
								tabIndex={-1}
								data-tip 
								data-for="email__input"
								className={`required ${emailStarted}`} 
							/>
							{emailAddressValidated === "valid" && (
								<CheckCircleOutlined
									style={{
										color: primaryColor
									}}
									className="success" />
							)}
						</div>
						<div>
							<input
								className={`${artistNameValidated}`}
								onKeyUp={() => artistNameValidation()}
								onBlur={() => checkArtist()}
								autoComplete="new-password"
								name="artistName"
								type="text"
								value={artistName}
								onChange={(e) => setArtistName(e.target.value)}
								required={true}
								spellCheck="false"
							/>
							<label style={{
								color: artistNameValidated === "valid" ? primaryColor : ""
							}}>Artist/Band Name</label>
							<ErrorOutlineOutlined
								tabIndex={-1}
								data-tip 
								data-for="artist-name__input"
								className={`required ${artistStarted}`} 
							/>
							{artistNameValidated === "valid" && (
								<CheckCircleOutlined
									style={{
										color: primaryColor
									}}
									className="success" />
							)}
						</div>
						<div className="form-btn__container">
							<button
								tabIndex={-1}
								data-tip 
								data-for="collab-submit__btn"
								className={`form-btn__submit ${validated ? "validated" : ""}`}
								onClick={(e) => validated && submitFunction(e)}
								style={{
									backgroundColor: buttonColor
								}}
							>
								Submit
							</button>
						</div>
						
					</form>
				</div>
			</div>
			{ !validated && <ToolTipBottom
				id={"collab-submit__btn"}
				message={"Please fill out all fields"} />}
		</Modal>
	);
};

export default CollaborateModal;

import {
	FC,
	createContext,
	useContext,
	useState,
	Dispatch,
	SetStateAction,
	useCallback,
} from "react";

import { WebSocket } from "isomorphic-ws";
import { useSpotifyAuthContext } from "./SpotifyAuthContext";
import { SongQueueContextParams } from "./QueueContext";
import { commaFunction } from "../Utils/commaMaker";
import { useSnackbar } from "notistack";

import { justWait } from "../Utils/tools";
import { HostCommandSocketBody, HostInfo, Requester, SongOrigin } from "../../types/api";


export interface PlayerState {
	artist: string;
	artistLink: string;
	contextUri: string;
	coverArt: string;
	requester: Requester;
	playing: boolean;
	songEnd: number;
	songLength: number;
	songLink: string;
	songPosition: number;
	songStart: number;
	timeQueued: string;
	title: string;
	origin: SongOrigin;
	volume: number;
}

export interface GuestCommands {
	pause: () => Promise<void>;
	play: (commandInput: { contextUri: string; positionMs: number; }) => Promise<void>;
	volume: (vol: number) => Promise<void>;
}

export interface TestCommands {
	pause: () => Promise<boolean>;
	play: () => Promise<boolean>;
}

export interface HostCommands {
	back: () => Promise<void>;
	drag: (positionMs: number) => Promise<void>;
	next: (queue: SongQueueContextParams) => Promise<void>;
	pause: () => Promise<void>;
	play: () => Promise<void>;
	volume: (vol: number) => Promise<void>;
}

interface SpotifyControlContextParams {
	guestCommands: GuestCommands;
	hostCommands: HostCommands;
	testCommands: TestCommands;

	playerState: PlayerState;
	setPlayerState: Dispatch<SetStateAction<PlayerState>>;

	socket: WebSocket | undefined;
	setSocket: Dispatch<SetStateAction<WebSocket | undefined>>;

	hostConnectionId: string;
	setHostConnectionId: Dispatch<SetStateAction<string>>;

	hostInfo: HostInfo | undefined;
	setHostInfo: Dispatch<SetStateAction<HostInfo | undefined>>;
}

export const defaultPlayerState: PlayerState = {
	playing: false,
	songEnd: 0,
	songLength: 0,
	songPosition: 0,
	songStart: 0,
	volume: 50,
	artist: "",
	artistLink: "",
	contextUri: "",
	coverArt: "",
	requester: {
		spotifyDisplayName: "",
		spotifyId: ""
	},
	songLink: "",
	title: "",
	origin: "host",
	timeQueued: ""
};

const SpotifyControlContext = createContext({} as SpotifyControlContextParams);

export const useSpotifyControlContext = (): SpotifyControlContextParams => {
	return useContext(SpotifyControlContext);
};

const SpotifyControlContextProvider: FC = ({ children }) => {
	// Contexts
	const { enqueueSnackbar } = useSnackbar();
	const { spotifyApi } = useSpotifyAuthContext();

	// Local State
	const [ socket, setSocket ] = useState<WebSocket>();
	const [ hostConnectionId, setHostConnectionId ] = useState<string>("");
	const [ playerState, setPlayerState ] = useState<PlayerState>(defaultPlayerState);
	const [ hostInfo, setHostInfo ] = useState<HostInfo>();

	const gcv = async (): Promise<SpotifyApi.CurrentlyPlayingResponse | undefined> => {
		let currentValue;
		const tries = 0;
		while (tries < 3 && !currentValue?.body.item) {
			try {
				currentValue = await spotifyApi.getMyCurrentPlayingTrack();
			} catch { null; }
			if (!currentValue?.body.item) {
				await justWait(1);
			}
		}
		return currentValue?.body;
	};

	const pause = useCallback(async (): Promise<boolean> => {
		let success = false;
		let pauseRes;

		try {
			pauseRes = await spotifyApi.pause();
			if ([ 200, 202, 204 ].includes(pauseRes.statusCode)) {
				success = true;
				enqueueSnackbar("Song paused", {
					variant: "info" 
				});
			}
		} catch { null; }
		if (!success) {
			try {
				await spotifyApi.play();
			} catch { null; }
			try {
				pauseRes = await spotifyApi.pause();
				if ([ 200, 202, 204 ].includes(pauseRes.statusCode)) {
					success = true;
					enqueueSnackbar("Song paused", {
						variant: "info" 
					});
				}
			} catch { null; }
		}

		return success;
	}, [ spotifyApi ]);

	const play = useCallback(async (): Promise<boolean> => {
		let success = false;
		let playRes;

		try {
			playRes = await spotifyApi.play();
			if ([ 200, 202, 204 ].includes(playRes.statusCode)) {
				success = true;
				enqueueSnackbar("Song playing", {
					variant: "info" 
				});
			}
		} catch { null; }
		if (!success) {
			try {
				await spotifyApi.pause();
			} catch { null; }
			try {
				playRes = await spotifyApi.play();
				if ([ 200, 202, 204 ].includes(playRes.statusCode)) {
					success = true;
					enqueueSnackbar("Song playing", {
						variant: "info" 
					});
				}
			} catch { null; }
		}

		return success;
	}, [ spotifyApi ]);

	const hostPlay = useCallback(async () => {
		await play();
		await justWait(1);

		const getCurrentValue = await gcv();
		if (!getCurrentValue?.item) return;

		const currentItem = getCurrentValue.item as SpotifyApi.TrackObjectFull;

		const newPlayerState: PlayerState = {
			...playerState,
			artist: commaFunction(currentItem.artists),
			artistLink: currentItem.artists[ 0 ].external_urls.spotify,
			contextUri: currentItem.uri,
			coverArt: currentItem.album.images[ 0 ].url,
			playing: getCurrentValue.is_playing,
			songEnd: Date.now() - (getCurrentValue.progress_ms ?? 0) + currentItem.duration_ms,
			songLength: currentItem.duration_ms,
			songLink: currentItem.external_urls.spotify,
			songPosition: getCurrentValue.progress_ms ?? 0,
			songStart: Date.now() - (getCurrentValue.progress_ms ?? 0),
			timeQueued: playerState.timeQueued,
			title: currentItem.name,
		};

		setPlayerState(newPlayerState);

		const newHostInfo: HostInfo = {
			...hostInfo!,
			hostAlbumArt: newPlayerState.coverArt,
			playerState: newPlayerState 
		};
		setHostInfo(newHostInfo);

		const websocketCommand: HostCommandSocketBody = {
			action: "hostCommand",
			command: "play",
			playerState: newPlayerState,
			hostInfo: newHostInfo,
			spotifyToken: spotifyApi.getAccessToken() ?? "",
		};

		socket?.send(
			JSON.stringify(websocketCommand),
		);

	}, [ spotifyApi, socket, play, playerState, hostInfo ]);

	const hostBack = useCallback(
		async () => {
			await spotifyApi.skipToPrevious();
			await justWait(1);

			const getCurrentValue = await gcv();
			if (!getCurrentValue || !getCurrentValue.item) {
				enqueueSnackbar("Failed to skip back", {
					variant: "info" 
				});
				return;
			}
			enqueueSnackbar("Song skipped back", {
				variant: "info" 
			});

			const currentItem = getCurrentValue.item as SpotifyApi.TrackObjectFull;

			let newPlayerState: PlayerState;

			if (currentItem.name === playerState.title) {
				newPlayerState = {
					...playerState,
					artist: commaFunction(currentItem.artists),
					artistLink: currentItem.artists[ 0 ].external_urls.spotify,
					contextUri: currentItem.uri,
					coverArt: currentItem.album.images[ 0 ].url,
					playing: getCurrentValue.is_playing,
					songEnd:
						Date.now() - (getCurrentValue.progress_ms ?? 0) + currentItem.duration_ms,
					songLength: currentItem.duration_ms,
					songLink: currentItem.external_urls.spotify,
					songPosition: getCurrentValue.progress_ms ?? 0,
					songStart: Date.now() - (getCurrentValue.progress_ms ?? 0),
					title: currentItem.name,
					volume: playerState.volume,
				};
			} else {
				newPlayerState = {
					...playerState,
					songPosition: getCurrentValue.progress_ms ?? 0,
					songStart: Date.now() - (getCurrentValue.progress_ms ?? 0),
					songEnd:
						Date.now() - (getCurrentValue.progress_ms ?? 0) + currentItem.duration_ms,
				};
			}

			setPlayerState(newPlayerState);

			const newHostInfo: HostInfo = {
				...hostInfo!,
				hostAlbumArt: newPlayerState.coverArt,
				playerState: newPlayerState 
			};
			setHostInfo(newHostInfo);

			const websocketCommand: HostCommandSocketBody = {
				action: "hostCommand",
				command: "play",
				playerState: newPlayerState,
				hostInfo: newHostInfo,
				spotifyToken: spotifyApi.getAccessToken() ?? "",
			};

			socket?.send(
				JSON.stringify(websocketCommand),
			);

		},
		[ spotifyApi, socket, hostConnectionId, playerState, pause ],
	);

	const hostPause = useCallback(async () => {
		await pause();
		await justWait(1);

		const getCurrentValue = await gcv();
		if (!getCurrentValue || !getCurrentValue.item) return;

		const currentItem = getCurrentValue.item as SpotifyApi.TrackObjectFull;

		const newPlayerState: PlayerState = {
			...playerState,
			title: currentItem.name,
			artist: commaFunction(currentItem.artists),
			artistLink: currentItem.artists[ 0 ].external_urls.spotify,
			contextUri: currentItem.uri,
			coverArt: currentItem.album.images[ 0 ].url,
			playing: getCurrentValue.is_playing,
			songEnd: Date.now() - (getCurrentValue.progress_ms ?? 0) + currentItem.duration_ms,
			songLength: currentItem.duration_ms,
			songLink: currentItem.external_urls.spotify,
			songPosition: getCurrentValue.progress_ms ?? 0,
			songStart: Date.now() - (getCurrentValue.progress_ms ?? 0),
			timeQueued: playerState.timeQueued,
		};

		const websocketCommand: HostCommandSocketBody = {
			action: "hostCommand",
			command: "pause",
			playerState,
			hostInfo: hostInfo!,
			spotifyToken: spotifyApi.getAccessToken() ?? "",
		};

		socket?.send(
			JSON.stringify(websocketCommand),
		);

		setPlayerState(newPlayerState);
	}, [ spotifyApi, socket, pause, playerState ]);

	const hostNext = useCallback(
		async (queue: SongQueueContextParams) => {
			await pause();
			const next = await queue.next(hostConnectionId);

			if (next) {

				await spotifyApi.play({
					uris: [ next.songInfo.uri! ],
				});
				await justWait(2);

				const getCurrentValue = await gcv();
				if (!getCurrentValue || !getCurrentValue.item) return;

				const currentItem = getCurrentValue.item as SpotifyApi.TrackObjectFull;

				const newPlayerState: PlayerState = {
					artist: commaFunction(currentItem.artists),
					artistLink: currentItem.artists[ 0 ].external_urls.spotify,
					contextUri: currentItem.uri,
					coverArt: currentItem.album.images[ 0 ].url,
					playing: getCurrentValue.is_playing,
					songEnd:
						Date.now() -
						(getCurrentValue.progress_ms ?? 0) +
						currentItem.duration_ms,
					songLength: currentItem.duration_ms,
					songLink: currentItem.external_urls.spotify,
					songPosition: getCurrentValue.progress_ms ?? 0,
					songStart: Date.now() - (getCurrentValue.progress_ms ?? 0),
					timeQueued: next.timeQueued,
					title: currentItem.name,
					volume: playerState.volume,
					requester: next.requester,
					origin: next.origin
				};

				const newHostInfo: HostInfo = {
					...hostInfo!,
					hostAlbumArt: newPlayerState.coverArt,
					playerState: newPlayerState 
				};
				setHostInfo(newHostInfo);

				const websocketCommand: HostCommandSocketBody = {
					action: "hostCommand",
					command: "play",
					hostInfo: newHostInfo,
					playerState: newPlayerState,
					spotifyToken: spotifyApi.getAccessToken() ?? "",
				};

				socket?.send(
					JSON.stringify(websocketCommand),
				);

				setPlayerState(newPlayerState);
			} else {
				await play();
				await justWait(3);

				const getCurrentValue = await gcv();
				if (!getCurrentValue || !getCurrentValue.item) return;

				const currentItem = getCurrentValue.item as SpotifyApi.TrackObjectFull;
				if (getCurrentValue) {

					const newPlayerState: PlayerState = {
						artist: commaFunction(currentItem.artists),
						artistLink: currentItem.artists[ 0 ].external_urls.spotify,
						contextUri: currentItem.uri,
						coverArt: currentItem.album.images[ 0 ].url,
						playing: true,
						requester: {
							spotifyDisplayName: "",
							spotifyId: ""
						},
						songEnd:
							Date.now() -
							(getCurrentValue.progress_ms ?? 0) +
							currentItem.duration_ms,
						songLength: currentItem.duration_ms,
						songLink: currentItem.external_urls.spotify,
						songPosition: getCurrentValue.progress_ms ?? 0,
						songStart: Date.now() - (getCurrentValue.progress_ms ?? 0),
						timeQueued: "",
						origin: "local",
						title: currentItem.name,
						volume: playerState.volume,
					};
					setPlayerState(newPlayerState);

					const newHostInfo: HostInfo = {
						...hostInfo!,
						hostAlbumArt: newPlayerState.coverArt,
						playerState: newPlayerState 
					};
					setHostInfo(newHostInfo);

					const websocketCommand: HostCommandSocketBody = {
						action: "hostCommand",
						hostInfo: newHostInfo,
						playerState: newPlayerState,
						command: "play",
						spotifyToken: spotifyApi.getAccessToken() ?? "",
					};

					socket?.send(
						JSON.stringify(websocketCommand),
					);
				}
			}
		},
		[ spotifyApi, socket, playerState, hostConnectionId, hostInfo ],
	);

	const hostDrag = useCallback(
		async (positionMs: number) => {
			await spotifyApi.seek(positionMs);
			if (playerState.playing) {
				await play();
			} else {
				await pause();
			}

			const getCurrentValue = await gcv();
			if (!getCurrentValue || !getCurrentValue.item) return;

			const currentItem = getCurrentValue.item as SpotifyApi.TrackObjectFull;

			const newPlayerState: PlayerState = {
				...playerState,
				artist: commaFunction(currentItem.artists),
				artistLink: currentItem.artists[ 0 ].external_urls.spotify,
				contextUri: currentItem.uri,
				coverArt: currentItem.album.images[ 0 ].url,
				playing: getCurrentValue.is_playing,
				songEnd:
					Date.now() - (positionMs ?? 0) + currentItem.duration_ms,
				songLength: currentItem.duration_ms,
				songLink: currentItem.external_urls.spotify,
				songPosition: positionMs ?? 0,
				songStart: Date.now() - (positionMs ?? 0),
				title: currentItem.name,
			};
			setPlayerState(newPlayerState);

			const newHostInfo: HostInfo = {
				...hostInfo!,
				hostAlbumArt: newPlayerState.coverArt,
				playerState: newPlayerState 
			};
			setHostInfo(newHostInfo);

			const websocketCommand: HostCommandSocketBody = {
				action: "hostCommand",
				command: "play",
				hostInfo: newHostInfo,
				playerState: newPlayerState,
				spotifyToken: spotifyApi.getAccessToken() ?? "",
			}; 

			socket?.send(
				JSON.stringify(websocketCommand),
			);

		},
		[ spotifyApi, socket, hostConnectionId, playerState ],
	);

	const hostVolume = useCallback(
		async (vol: number) => {
			await spotifyApi.setVolume(vol);
			await justWait(1);

			const getCurrentValue = await gcv();
			if (!getCurrentValue || !getCurrentValue.item) return;

			const currentItem = getCurrentValue.item as SpotifyApi.TrackObjectFull;
			setPlayerState((prev: PlayerState) => {
				return {
					...prev,
					artist: commaFunction(currentItem.artists),
					artistLink: currentItem.artists[ 0 ].external_urls.spotify,
					contextUri: currentItem.uri,
					coverArt: currentItem.album.images[ 0 ].url,
					playing: getCurrentValue.is_playing,
					songEnd:
						Date.now() - (getCurrentValue.progress_ms ?? 0) + currentItem.duration_ms,
					songLength: currentItem.duration_ms ?? 0,
					songLink: currentItem.external_urls.spotify,
					songPosition: getCurrentValue.progress_ms ?? 0,
					songStart: Date.now() - (getCurrentValue.progress_ms ?? 0),
					timeQueued: prev.timeQueued,
					title: currentItem.name,
					volume: vol,
				};
			});
		},
		[ spotifyApi, socket, playerState ],
	);

	const guestPause = useCallback(async () => {
		await pause();
		await justWait(1);

		const getCurrentValue = await gcv();
		if (!getCurrentValue || !getCurrentValue.item) return;

		const currentItem = getCurrentValue.item as SpotifyApi.TrackObjectFull;

		const newPlayerState: PlayerState = {
			...playerState,
			artist: commaFunction(currentItem.artists),
			artistLink: currentItem.artists[ 0 ].external_urls.spotify,
			contextUri: currentItem.uri,
			coverArt: currentItem.album.images[ 0 ].url,
			playing: getCurrentValue.is_playing,
			songEnd: Date.now() - (getCurrentValue.progress_ms ?? 0) + currentItem.duration_ms,
			songLength: currentItem.duration_ms,
			songLink: currentItem.external_urls.spotify,
			songPosition: getCurrentValue.progress_ms ?? 0,
			songStart: Date.now() - (getCurrentValue.progress_ms ?? 0),
			timeQueued: playerState.timeQueued,
			title: currentItem.name,
			volume: playerState.volume,
		};

		setPlayerState(newPlayerState);
	}, [ spotifyApi, socket, pause, playerState ]);

	const guestPlay = useCallback(
		async (commandInput: { contextUri: string; positionMs: number; }) => {
			await spotifyApi.play({
				uris: [ commandInput.contextUri! ],
				position_ms: commandInput.positionMs,
			});
			await justWait(1);

			const getCurrentValue = await gcv();
			if (!getCurrentValue || !getCurrentValue.item) {
				enqueueSnackbar("Failed to play song", {
					variant: "error" 
				});
				return;
			}

			const currentItem = getCurrentValue.item as SpotifyApi.TrackObjectFull;

			const newPlayerState: PlayerState = {
				...playerState,
				artist: commaFunction(currentItem.artists),
				artistLink: currentItem.artists[ 0 ].external_urls.spotify,
				contextUri: currentItem.uri,
				coverArt: currentItem.album.images[ 0 ].url,
				playing: getCurrentValue.is_playing,
				songEnd: Date.now() - (getCurrentValue.progress_ms ?? 0) + currentItem.duration_ms,
				songLength: currentItem.duration_ms,
				songLink: currentItem.external_urls.spotify,
				songPosition: getCurrentValue.progress_ms ?? 0,
				songStart: Date.now() - (getCurrentValue.progress_ms ?? 0),
				timeQueued: playerState.timeQueued,
				title: currentItem.name,
				volume: playerState.volume,
			};

			setPlayerState(newPlayerState);
		},
		[ spotifyApi, socket, playerState ],
	);

	const guestVolume = useCallback(
		async (vol: number) => {
			await spotifyApi.setVolume(vol);
			setPlayerState((prev) => {
				return {
					...prev,
					volume: prev.volume,
				};
			});
		},
		[ spotifyApi ],
	);

	return (
		<SpotifyControlContext.Provider
			value={{
				socket,
				setSocket,
				playerState,
				setPlayerState,
				hostConnectionId,
				setHostConnectionId,
				hostCommands: {
					play: hostPlay,
					back: hostBack,
					pause: hostPause,
					next: hostNext,
					drag: hostDrag,
					volume: hostVolume,
				},
				guestCommands: {
					play: guestPlay,
					pause: guestPause,
					volume: guestVolume,
				},
				testCommands: {
					pause,
					play,
				},
				hostInfo,
				setHostInfo
			}}
		>
			{children}
		</SpotifyControlContext.Provider>
	);
};

export { SpotifyControlContextProvider };

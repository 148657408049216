// React
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// Components
import { HostFormModal, LoginModal } from "../../Global/Modals";
import { QueueJukeBox } from "../Queue";
import { MediaControls, SearchBar, SongCards } from "../../Global";

// WebSocket
import WebSocket from "isomorphic-ws";
import { websocketUrl } from "../../../Utils/websocketUrl";

// Contexts
import { useQueueContext } from "../../../Contexts/QueueContext";
import { useSpotifyAuthContext } from "../../../Contexts/SpotifyAuthContext";
import { useSpotifyControlContext } from "../../../Contexts/SpotifyControlContext";
import { commaFunction } from "../../../Utils/commaMaker";

// Types
import { HostConnectBody, HostInfo, JukeboxTokens } from "../../../../types/api";
import "./HostPage.scss";
import { useSnackbar } from "notistack";

const HostPageComponent = (): JSX.Element => {
	const queue = useQueueContext();
	const { enqueueSnackbar } = useSnackbar();

	const [ submitSuccessful, setSubmitSuccessful ] = useState<boolean>(false);
	const [ submitted, setSubmitted ] = useState<boolean>(false);
	const [ updatingSong, setUpdatingSong ] = useState<boolean>(false);
	const [ searchStarted, setSearchStarted ] = useState<boolean>(false);

	// Contexts and hooks
	const { spotifyApi, spotifyProfile, spotifyLoggedIn } = useSpotifyAuthContext();
	const { playerState, setPlayerState, hostCommands } = useSpotifyControlContext();
	const { socket, setSocket, hostConnectionId, setHostConnectionId, setHostInfo, hostInfo } = useSpotifyControlContext();
	const navigate = useNavigate();

	// Submission state
	const [ jukeboxOn, setJukeboxOn ] = useState<boolean>(false);
	const [ explicitMusic, setExplicitMusic ] = useState<boolean>(true);
	const [ spotifyReady, setSpotifyReady ] = useState<boolean>(false);
	const [ jukeboxTokens, setJukeboxTokens ] = useState<number>(1);

	// Song search state
	const [ songSearchResult, setSongSearchResult ] = useState<SpotifyApi.TrackObjectFull[]>([]);
	const [ tickerInterval, setTickerInterval ] = useState<NodeJS.Timeout>();
	const [ ticker, toggleTicker ] = useState<boolean>(false);
	const [ position, setPosition ] = useState<number>(0);
	const [ searchValue, setSearchValue ] = useState<string>("");
	const [ selectedSong, setSelectedSong ] = useState<SpotifyApi.TrackObjectFull>();
	const [ showLoginModal, setShowLoginModal ] = useState<boolean>(false);

	// Socket Functions
	const onClose = () => {
		console.log("websocket closed");
		navigate("/home");
	};

	useEffect(() => {
		const timeoutFunction = setTimeout(() => {
			if(!spotifyLoggedIn) setShowLoginModal(true);
		}, 3000);

		return () => clearTimeout(timeoutFunction);
	}, [ spotifyLoggedIn ]);

	const onMessage = useCallback(
		async (e: any) => {
			const data = JSON.parse(e.data);

			if(data.unauthorized){
				navigate("/home");
			}

			if (data.source == "hostConnect") {
				if(!data.success) {
					setSubmitSuccessful(data.success);
					enqueueSnackbar("Failed to submit host info, sorry!", { variant: "error" });
				} else {
					setSubmitSuccessful(data.success);
					setHostConnectionId(data.connectionId);
					setHostInfo(data.hostInfo);
					window.localStorage.setItem("hostConnectionId", data.connectionId);
					const socketMessage: JukeboxTokens = {
						action: "createTokens",
						hostConnectionId,
						hostInfo: data.hostInfo,
						spotifyToken: spotifyApi.getAccessToken() ?? "",
						jukeboxTokens
					};
					if(jukeboxOn){
						socket?.send(
							JSON.stringify(socketMessage)
						);
					}
				} 
				
			}
			if (data.source == "hostUpdate" && socket && hostConnectionId) {
				await queue.get(hostConnectionId);
			}
			if(data.source == "createTokens"){
				if(data.success){
					enqueueSnackbar("Successfully distributed tokens!", { variant: "success" });
					if(!hostInfo){
						return;
					}
					setHostInfo(prev => ({
						...prev!,
						tokensRemaining: data.tokensRemaining
					}));
				}
			}
		},
		[ hostConnectionId, playerState, queue.get ],
	);

	const initStreamSocket = () => {
		const socket = new WebSocket(websocketUrl());
		socket.onopen = async (e) => {
			const initialHostInfo: HostInfo = {
				explicitMusic,
				hostAlbumArt: (((await spotifyApi.getMyCurrentPlayingTrack()).body.item) as SpotifyApi.TrackObjectFull).album.images[ 0 ].url,
				hostConnectionId,
				hostSpotifyDisplayName: spotifyProfile?.display_name ?? "",
				hostSpotifyId: spotifyProfile?.id ?? "",
				jukeboxOn,
				playerState,
			};
			setHostInfo(initialHostInfo);
			const socketMessage: HostConnectBody = {
				action: "hostConnect",
				info: initialHostInfo,
				spotifyToken: spotifyApi.getAccessToken() ?? "",
			};
			socket.send(
				JSON.stringify(socketMessage),
			);
		};
		socket.onclose = onClose;
		socket.onmessage = onMessage;
		setSocket(socket);
	};

	// Song search functions

	useEffect(() => {
		const characters = "abcdefghijklmnopqrstuvwxyz0123456789";

		const random = Math.floor(Math.random() * characters.length);

		const searchSongStart = async () => {
			await spotifyApi
				.searchTracks(characters.slice(random, random + 1) ?? "a", {
					limit: 20, 
				})
				.then((res) => {
					if (res.statusCode == 200 && res.body.tracks)
						setSongSearchResult(
							res.body.tracks.items.filter((track) => {
								return track.explicit != true;
							}),
						);
				});
		};

		searchSongStart();
	}, []);

	useEffect(() => {
		searchValue && spotifyApi.searchTracks(searchValue, {
			limit: 20 
		}).then((res) => {
			if (res.statusCode == 200 && res.body.tracks)
				setSongSearchResult(
				    res.body.tracks.items.filter((track) => {
				        return explicitMusic ? true : track.explicit != true;
				    }),
				);
		});
	}, [ searchStarted ]);


	// Triggered when the host form is submitted
	useEffect(() => {
		if (submitted) {
			initStreamSocket();
		}
	}, [ submitted ]);

	useEffect(() => {
		const initPlayer = async () => {
			const currentPlayback = await spotifyApi.getMyCurrentPlaybackState();
			if (currentPlayback && currentPlayback.body && currentPlayback.body.item) {
				const currentItem = currentPlayback.body.item as SpotifyApi.TrackObjectFull;
				setPlayerState({
					requester: {
						spotifyDisplayName: spotifyProfile?.display_name ?? "",
						spotifyId: spotifyProfile?.id ?? ""
					},
					origin: "host",
					timeQueued: "",
					title: currentItem.name,
					artist: commaFunction(currentItem.artists),
					coverArt: currentItem.album.images[ 0 ].url,
					playing: false,
					songLength: currentItem.duration_ms,
					songPosition: currentPlayback.body.progress_ms ?? 0,
					songStart: Date.now() - (currentPlayback.body.progress_ms ?? 0),
					songEnd:
						Date.now() -
						(currentPlayback.body.progress_ms ?? 0) +
						currentItem.duration_ms,
					volume: 50,
					songLink: currentPlayback.body.item?.external_urls.spotify,
					artistLink: currentItem.artists[ 0 ].external_urls.spotify,
					contextUri: currentItem.uri
				});
				setPosition(Math.round((currentPlayback.body.progress_ms?? 0) / 1000));
			}
		};

		initPlayer();
	}, [ spotifyReady ]);

	const tick = useCallback(async () => {
		if (playerState.playing && hostCommands && hostConnectionId && !updatingSong) {
			if (position < (playerState.songLength - 2500) / 1000) {
				setPosition(() =>
					Math.round(
						(playerState.songLength - (playerState.songEnd - Date.now())) / 1000,
					),
				);
			} else {
				setPosition(0);
				setUpdatingSong(true);
				await hostCommands.next(queue);
				setUpdatingSong(false);
			}
		}

	}, [ playerState, position, tickerInterval, hostConnectionId, hostCommands, queue ]);

	useEffect(() => {
		tick();
	}, [ ticker ]);

	useEffect(() => {
		if (!playerState.playing || updatingSong) {
			clearInterval(tickerInterval);
			setTickerInterval(undefined);
		} else {
			!tickerInterval && setTickerInterval(
				setInterval(() => {
					toggleTicker((prev) => !prev);
				}, 1000),
			);
		}

		return () => {
			clearInterval(tickerInterval);
		};
	}, [ playerState, updatingSong ]);

	useEffect(() => {
		if (hostConnectionId && socket) {
			socket.onmessage = onMessage;
			queue.get(hostConnectionId);
		}
	}, [ hostConnectionId ]);

	useEffect(() => {
		let pingInterval: any;
		if (socket) {
			pingInterval = setInterval(() => {
				socket.send(JSON.stringify({
					action: "ping"
				}));
			}, 1000 * 30);
		}

		return () => {
			clearInterval(pingInterval);
		};
	}, [ socket ]);

	const hostQueueSong = useCallback(
		async (songInfo: SpotifyApi.TrackObjectFull) => {

			delete songInfo.available_markets;
			delete songInfo.album.available_markets;

			if (socket) {
				await queue.add(songInfo, hostConnectionId, "host");
			}
		},
		[ socket, hostConnectionId, playerState, queue.add ],
	);

	const giveTokens = useCallback(() => {
		if(socket && hostConnectionId){
			socket.send(JSON.stringify({
				action: "createTokens",
				hostConnectionId,
				hostInfo,
				spotifyToken: spotifyApi.getAccessToken() ?? "",
				jukeboxTokens
			}));
		}
	}, [ socket, hostConnectionId, hostInfo, spotifyApi ]);

	useEffect(() => {
		if(selectedSong) hostQueueSong(selectedSong);
	}, [ selectedSong ]);

	const skipBackFunction = useCallback(async () => {
		clearInterval(tickerInterval);
		await hostCommands.back();
	}, [ hostCommands, queue ]);

	const skipForwardFunction = useCallback(async () => {
		setUpdatingSong(true);
		await hostCommands.next(queue);
		setUpdatingSong(false);
	}, [ hostCommands, queue ]);

	const dragTime = useCallback(
		async (time: number) => {
			setUpdatingSong(true);
			await hostCommands.drag(time * 1000);
			setUpdatingSong(false);
		},
		[ hostCommands, setUpdatingSong ],
	);

	const stopCounter = useCallback(() => {
		setUpdatingSong(true);
	}, []);

	const setSongCurrentTime = useCallback(
		async (time: number) => {
			setUpdatingSong(true);
			setPosition(time);
		},
		[ tickerInterval ],
	);

	return (
		<div className="host-page__container">
			{showLoginModal && <LoginModal
				redirectLocation="/home"
				setShowModal={setShowLoginModal}/>}
			{!submitSuccessful && (
				<HostFormModal
					jukeBoxOn={jukeboxOn}
					setJukeBoxOn={setJukeboxOn}
					explicitMusic={explicitMusic}
					setExplicitMusic={setExplicitMusic}
					submitSuccessful={submitSuccessful}
					setSubmitted={setSubmitted}
					spotifyReady={spotifyReady}
					setSpotifyReady={setSpotifyReady}
				/>
			)}
			<SearchBar 
				setSearchStarted={setSearchStarted} 
				searchValue={searchValue} 
				setSearchValue={setSearchValue}
			/>
			<div className="host-page__cards-queue-container">
				<SongCards
					searchStarted={searchStarted}
					songSearchResult={songSearchResult}
					setSelectedSong={setSelectedSong} />
				<QueueJukeBox
					style={"host"}
					jukeboxTokens={jukeboxTokens}
					setJukeboxTokens={setJukeboxTokens}
					giveTokens={giveTokens}
					hostJukebox={jukeboxOn}
					jukebox={jukeboxOn}/>
			</div >
			<div className="media-controls__container">
				{hostCommands && spotifyReady && (
					<MediaControls
						playing={playerState.playing}
						setPlaying={playerState.playing ? hostCommands.pause : hostCommands.play}
						skipBack={skipBackFunction}
						skipForward={skipForwardFunction}
						volumeLevel={playerState.volume}
						setVolumeLevel={hostCommands.volume}
						songLength={playerState.songLength / 1000}
						dragTime={dragTime}
						stopCounter={stopCounter}
						setSongCurrentTime={setSongCurrentTime}
						songCurrentTime={position}
						songCurrentInfo={{
							albumArt: playerState.coverArt,
							artist: playerState.artist,
							title: playerState.title,
							songLink: playerState.songLink,
							artistLink: playerState.artistLink
						}}
						style={"host-bar"}
					/>
				)}
			</div>
		</div >
	);
};

export default HostPageComponent;

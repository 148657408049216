
// React
import { useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// Components
import { GuestCheck } from "../../Global/Modals";
import JukeOffGuest from "./JukeOffGuest";
import JukeOnGuest from "./JukeOnGuest";
import { Verifying } from "../../Authentication";

// Contexts
import { useQueueContext } from "../../../Contexts/QueueContext";
import { useSpotifyAuthContext } from "../../../Contexts/SpotifyAuthContext";
import { useSpotifyControlContext } from "../../../Contexts/SpotifyControlContext";

// Types
import { HostCommandSocketBody, HostInfo } from "../../../../types/api";

// WebSocket
import WebSocket from "isomorphic-ws";
import { websocketUrl } from "../../../Utils/websocketUrl";
import { Queue } from "../../../../types/frontend";

const Guest = (): JSX.Element => {
	const { connectionId } = useParams();
	const navigate = useNavigate();

	const { spotifyApi } = useSpotifyAuthContext();
	const { guestCommands, socket, setSocket, playerState, setHostConnectionId, hostInfo, setHostInfo } = useSpotifyControlContext();
	const queue = useQueueContext();
	const [ rollover, setRollover ] = useState<boolean>(false);
	const [ jukeboxTokens, setJukeboxTokens ] = useState<string[]>([]);

	// Song search state
	const [ isVerified, setIsVerified ] = useState<boolean>(false);
	const [ position, setPosition ] = useState<number>(0);
	const [ showModal, setShowModal ] = useState<boolean>(true);
	const [ ticker, toggleTicker ] = useState<boolean>(false);
	const [ tickerInterval, setTickerInterval ] = useState<any>();

	const tick = useCallback(async () => {
		if (playerState.playing) {
			if (position < (playerState.songLength - 1500) / 1000) {
				setPosition(() =>
					Math.round(
						(playerState.songLength -
							(playerState.songEnd - Date.now())) /
						1000,
					),
				);
			} else {
				setRollover(true);
				setPosition(0);
			}
		}
	}, [ playerState, position, tickerInterval ]);

	useEffect(() => {
		tick();
	}, [ ticker ]);

	const onClose = () => {
		navigate("/home");
	};

	useEffect(() => {
		if (rollover || !playerState.playing) {
			clearInterval(tickerInterval);
			setTickerInterval(undefined);
		} else {
			setTickerInterval(
				setInterval(() => {
					toggleTicker((prev) => !prev);
				}, 1000),
			);
		}

		return () => {
			clearInterval(tickerInterval);
		};
	}, [ playerState, rollover ]);

	const onMessage = useCallback(async (e: any) => {

		
		if(connectionId && socket){
			
			const body = JSON.parse(e.data);

			if(body.unauthorized){
				navigate("/home");
			}


			if (body.source == "guestConnect" && connectionId) {
				if (!body.success) {
					navigate("/home");
				}
				const { hostInfo: newHostInfo, queue: newQueue }: { hostInfo: HostInfo; queue: Queue; } = body;
				queue.set(newQueue);
				setHostInfo(newHostInfo);
				setHostConnectionId(newHostInfo.hostConnectionId);
			}

			if (body.source == "hostDisconnect") {
				socket?.close();
			}

			if(body.source == "createTokens") {
				setJukeboxTokens(prev => [ ...prev, body.jukeboxToken ]);
			}

			if (body.action && body.action == "hostCommand" && guestCommands) {
				setRollover(false);
				const typedBody: HostCommandSocketBody = body;
				switch (typedBody.command) {
				case "play":
					await guestCommands.play({
						contextUri: body.playerState.contextUri,
						positionMs: (body.playerState.songLength - (body.playerState.songEnd - Date.now()))
					});
					break;
				case "pause":
					await guestCommands.pause();
					break;
				case "updateQueue":
					if(typedBody.queue){
						queue.set(typedBody.queue);
					}
					break;
				}
			}

		}

	}, [ socket, connectionId, guestCommands ]);

	// 1. INIT SOCKET
	useEffect(() => {
		const socket = new WebSocket(websocketUrl());
		const socketMessage = {
			action: "guestConnect",
			hostConnectionId: connectionId,
			spotifyToken: spotifyApi.getAccessToken(),
		};
		socket.onopen = () => {
			socket.send(
				JSON.stringify(socketMessage),
			);
		};
		socket.onclose = onClose;
		socket.onmessage = onMessage;
		setSocket(socket);
	}, []);

	useEffect(() => {
		if (socket) socket.onmessage = onMessage;
	}, [ onMessage, socket ]);

	useEffect(() => {
		let pingInterval: any;
		if (socket) {
			pingInterval = setInterval(() => {
				socket.send(JSON.stringify({
					action: "ping"
				}));
			}, 1000 * 240);
		}
		return () => {
			clearInterval(pingInterval);
		};
	}, [ socket ]);

	return (
		<>
			{!isVerified && (
				<Verifying
					isChecked={!!hostInfo}
					setIsVerified={setIsVerified} />
			)}
			{showModal && isVerified && hostInfo && <GuestCheck
				setShowModal={setShowModal}
				hostInfo={hostInfo} />}
			{isVerified && (
				<>
					{!hostInfo?.jukeboxOn && playerState && (
						<JukeOffGuest position={position} />
					)}
					{hostInfo?.jukeboxOn && (
						<JukeOnGuest
							position={position}
							jukeboxTokens={jukeboxTokens}
							setJukeboxTokens={setJukeboxTokens}
						/>
					)}
				</>
			)}
		</>
	);
};

export default Guest;

// React
import { useEffect, useState, useMemo } from "react";

// Components
import HomePageComponent from "../../Components/Authenticated/HomePage/HomePage";
import { StartSyncButton } from "../../Components/Global/Buttons";

// Contexts
import { MenuItem, useLayoutContext, PageModal } from "../../Contexts/LayoutContext";
import { useSpotifyAuthContext } from "../../Contexts/SpotifyAuthContext";

// Icons
import {
	Help,
	LibraryMusicRounded,
	MonetizationOn,
	Palette,
	AccountBox
} from "@mui/icons-material";

// Modals
import { AboutPageModal, ThemeModal, CollaborateModal } from "../../Components/Global/Modals";

const HomePage = (): JSX.Element => {

	// Contexts
	const { setMenuItems, setModals, setCustomMenuItems } = useLayoutContext();
	const { loginSpotify, logoutSpotify, spotifyLoggedIn } = useSpotifyAuthContext();

	// State
	const [ fromAboutModal, setFromAboutModal ] = useState<boolean>(false);
	const [ showAboutModal, setShowAboutModal ] = useState<boolean>(false);
	const [ showCollaborateModal, setShowCollaborateModal ] = useState<boolean>(false);
	const [ showThemeModal, setShowThemeModal ] = useState<boolean>(false);

	const showSelectedModal = (modal: string) => {
		if(modal === "about") {
			setShowAboutModal(true);
			setShowThemeModal(false);
			setShowCollaborateModal(false);
		} else if(modal === "theme"){
			setShowAboutModal(false);
			setShowThemeModal(true);
			setShowCollaborateModal(false);
		} else if(modal === "collaborate"){
			setShowAboutModal(false);
			setShowThemeModal(false);
			setShowCollaborateModal(true);
		}
	};

	const resetModals = () => {
		setShowAboutModal(false);
		setShowThemeModal(false);
		setShowCollaborateModal(false);
		window.open("https://www.patreon.com/streamsync");
	};

	const customMenuItems: JSX.Element[] = useMemo(() => {
		return [ <StartSyncButton key="1"/> ];
	}, []);

	const menuItems: MenuItem[] = useMemo(() => {
		return [
			{
				icon: AccountBox,
				text: spotifyLoggedIn ? "Logout" : "Login",
				onClick: spotifyLoggedIn ? () => logoutSpotify() : () => loginSpotify()
			},
			{
				icon: Help,
				text: "About",
				onClick: () => showSelectedModal("about"),
				highlight: true
			},
			{
				icon: Palette,
				text: "Theme",
				onClick: () => showSelectedModal("theme"),
				highlight: true
			},
			{
				icon: MonetizationOn,
				text: "Donate",
				onClick: () => resetModals()
			},
			{
				icon: LibraryMusicRounded,
				text: "Collaborate",
				onClick: () => showSelectedModal("collaborate"),
				highlight: true
			}
		];
	}, [ showAboutModal, showThemeModal, fromAboutModal, showCollaborateModal, loginSpotify, logoutSpotify ]);

	const modals: PageModal[] = useMemo(() => {
		 return [
			{
				modal: <AboutPageModal
					setFromAbout={setFromAboutModal}
					setShowAboutModal={setShowAboutModal}
					setShowCollabModal={setShowCollaborateModal}/>,
				show: showAboutModal,
				setShow: setShowAboutModal
			},
			{
				modal: <ThemeModal setShowModal={setShowThemeModal}/>,
				show: showThemeModal,
				setShow: setShowThemeModal
			},
			{
				modal: <CollaborateModal
					setShowCollabModal={setShowCollaborateModal}
					fromAbout={fromAboutModal}
					setFromAbout={setFromAboutModal}/>,
				show: showCollaborateModal,
				setShow: setShowCollaborateModal,
			}
		];
	}, [ showThemeModal, showAboutModal, fromAboutModal, showCollaborateModal ]); 

	useEffect(() => {
		setMenuItems(menuItems);
		setModals(modals);
		setCustomMenuItems(customMenuItems);
	}, [ setMenuItems, setModals, showThemeModal, showAboutModal, fromAboutModal, showCollaborateModal, loginSpotify, logoutSpotify, spotifyLoggedIn ]);

	return (
		<HomePageComponent />
	);
};

export default HomePage;
import { useState } from "react";
import { Modal } from "../../../../Contexts/Modals";
import { useThemeContext } from "../../../../Contexts/ThemeContext";
import { CloseButton } from "../../Buttons";
import WalkthroughModal from "../Walkthrough/WalkthroughModal";
import "./aboutPage.scss";

type AboutPageModalProps = {
	setFromAbout: (fromAbout: boolean) => void;
	setShowAboutModal: (showAboutModal: boolean) => void;
	setShowCollabModal: (showCollabModal: boolean) => void;
};

const AboutPageModal = (props: AboutPageModalProps): JSX.Element => {
	const { primaryColor } = useThemeContext();
	const [ fadeOut, setFadeOut ] = useState<string>("");
	const [ showWalkThroughModal, setShowWalkThroughModal ] = useState<boolean>(false);

	const collaborateOpen = () => {
		props.setFromAbout(true);
		setFadeOut("fade-out");
		props.setShowCollabModal(true);
		setTimeout(() => {
			props.setShowAboutModal(false);
		}, 400);
	};

	return (
		<Modal fadeOut={fadeOut}>
			{showWalkThroughModal && <WalkthroughModal setShowModal={setShowWalkThroughModal}/>}
			<div className="about-page__container">
				<CloseButton
					setShowModal={props.setShowAboutModal}
					setFadeOut={setFadeOut}/>
				<div className="about-page__text--container">
					<h1 style={{
						color: primaryColor
					}}>About StreamSync</h1>
					<p>
						We designed this site so streamers can listen to whatever music they want to
						through{" "}
						<a
							style={{
								color: primaryColor
							}}
							href="https://www.spotify.com/us/"
							target="_blank"
							rel="noopener noreferrer"
						>
							<span></span>
							Spotify
						</a>{" "}
						with their viewers, without having to worry about copyright issues. This gives
						another way for the streamer to interact, while still
						giving the artists the recognition and revenue they deserve.
					</p>
					<p>
						One of our favorite features is Jukebox mode. Want
						your favorite streamer to hear your new favorite song? Throw some change
						into the Jukebox and add it to the queue.
					</p>
					<p>
						We also want to shed light on musicians and artists that are on the come up.
						We plan to create a place where you can check out new music from smaller
						artists that want to put their music out there. If you want your
						music featured on the site, reach out through our{" "}
						<button
							style={{
								color: primaryColor
							}}
							onClick={() => collaborateOpen()}>
							<span></span>
							Collaborate
						</button>{" "}
						page.
					</p>
					<p>
						Finally, our platform is free, but we're a small team with some pretty big
						ideas. We are continuing to improve StreamSync and would love to bring more
						ideas to life. If you want to help us make the site better, you can head
						over to our{" "}
						<a
							href="https://www.patreon.com/"
							target="_blank"
							rel="noopener noreferrer"
							style={{
								color: primaryColor
							}}
						>
							<span></span>
							Patreon
						</a>{" "}
						page and donate.
					</p>
					<p>
						For more information and a walk through click{" "}
						<button
							style={{
								color: primaryColor, 
								fontSize: "1.3vw"
							}}
							onClick={() => setShowWalkThroughModal(true)}>
							<span></span>
							here
						</button>
					</p>
					<h3 style={{
						color: primaryColor
					}}>- Jesse and Taten -</h3>
				</div>
			</div>
		</Modal>
	);
};

export default AboutPageModal;

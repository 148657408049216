import ReactTooltip from "react-tooltip";
import "./ToolTip.scss";

type ToolTipBottomArrowProps = {
    message: string,
    id: string
}

const ToolTipRight = (props: ToolTipBottomArrowProps) => {

	return (
		<ReactTooltip
			className="tool-tip__right"
			arrowColor="transparent"
			id={props.id} 
			place="right" 
			effect="solid"
		>
			{props.message}
		</ReactTooltip>
	);
};

export default ToolTipRight;
const verse = require("./Verse.mp3");
const aliens = require("./Aliens.mp3");
const pirates = require("./Pirates.mp3");

export interface SongInfo {
	song: any;
	title: string;
	artist: string;
	albumName: string;
	albumArt: string;
	songLink: string;
	artistLink: string;
}

const tracks: SongInfo[] = [
	{
		song: verse,
		title: "Verse",
		artist: "YFNFB",
		albumName: "Trying",
		albumArt: "https://i.scdn.co/image/ab67616d0000b273504f19f1c99cbcb25efde1de",
		songLink: "https://open.spotify.com/track/78j0QvhL9o1eYyylRU0vtt?si=46888ae48af5425b",
		artistLink:
			"https://open.spotify.com/artist/6ttUyfihdWcGOOzdrmSTi3?si=oJU-BMa0QcOIn722npmkvg",
	},
	{
		song: aliens,
		title: "Step",
		artist: "YFNFB",
		albumName: "Trying",
		albumArt: "https://i.scdn.co/image/ab67616d0000b273504f19f1c99cbcb25efde1de",
		songLink: "https://open.spotify.com/track/0cUwMxUI1Ls2hBktQMV0NN?si=df0fdca4ff7a4fc8",
		artistLink:
			"https://open.spotify.com/artist/6ttUyfihdWcGOOzdrmSTi3?si=oJU-BMa0QcOIn722npmkvg",
	},
	{
		song: pirates,
		title: "Pirates",
		artist: "YFNFB",
		albumName: "Trying",
		albumArt: "https://i.scdn.co/image/ab67616d0000b273504f19f1c99cbcb25efde1de",
		songLink: "https://open.spotify.com/track/0tTCq85ILinXg2IGwn4tVy?si=94471aa296d948ab",
		artistLink:
			"https://open.spotify.com/artist/6ttUyfihdWcGOOzdrmSTi3?si=oJU-BMa0QcOIn722npmkvg",
	},
];

export default tracks;

import {
	FC,
	useContext,
	createContext,
	useState,
	Dispatch,
	SetStateAction
} from "react";

export interface MenuItem {
	icon: any,
	onClick: (e: any) => any;
	text: string;
	highlight?: boolean;
}

export type PageModal = {
	modal: JSX.Element,
	show: boolean,
	setShow: Dispatch<SetStateAction<boolean>>;
};

export interface LayoutContextParams {
	menuItems: MenuItem[];
	setMenuItems: Dispatch<SetStateAction<MenuItem[]>>;
	customMenuItems: JSX.Element[];
	setCustomMenuItems: Dispatch<SetStateAction<JSX.Element[]>>;
	modals: PageModal[];
	setModals: Dispatch<SetStateAction<PageModal[]>>;
	navBarHovered: boolean;
	setNavBarHovered: Dispatch<SetStateAction<boolean>>;
}

const LayoutContext = createContext({} as LayoutContextParams);

export const useLayoutContext = (): LayoutContextParams => {
	return useContext(LayoutContext);
};

export const LayoutContextProvider: FC = ({ children }) => {
	const [ menuItems, setMenuItems ] = useState<MenuItem[]>([]);
	const [ modals, setModals ] = useState<PageModal[]>([]);
	const [ customMenuItems, setCustomMenuItems ] = useState<JSX.Element[]>([]);
	const [ navBarHovered, setNavBarHovered ] = useState<boolean>(false);

	return (
		<LayoutContext.Provider
			value={{
				menuItems,
				setMenuItems,
				modals,
				setModals,
				customMenuItems,
				setCustomMenuItems,
				navBarHovered,
				setNavBarHovered 
			}}
		>
			<>{children}</>
		</LayoutContext.Provider>
	);
};

import { useEffect, useRef } from "react";
import SpotifyLogo from "../../Global/Images/Spotify_Logo_RGB_Green.png";
import { useThemeContext } from "../../../Contexts/ThemeContext";
import "./SongCards.scss";

type SongCardsProps = {
    searchStarted: boolean;
    songSearchResult: SpotifyApi.TrackObjectFull[];
    setSelectedSong: (jukeBoxSong: SpotifyApi.TrackObjectFull | undefined) => void;
};

const SongCards = (props: SongCardsProps): JSX.Element => {
	const cardContainer = useRef<HTMLDivElement>(null);
	const { primaryColor } = useThemeContext();

	useEffect(() => {
		if (props.searchStarted && cardContainer.current) {
			cardContainer.current.scroll({
				top: 0,
				behavior: "smooth" 
			});
		}
	}, [ props.searchStarted ]);

	return (
		<div
			className="song-cards__container"
			ref={cardContainer}>
			{props.songSearchResult && props.songSearchResult.map((result, i) => {
				return (
					<div
						key={i}
						onClick={() => props.setSelectedSong(result)}
						className="song-card"
					>
						<div className="song-card__top-section">
							<img src={result.album.images[ 0 ].url}></img>
						</div>
						<div className="song-card__bottom-section">
							<div className="bottom-section__logo-container">
								<img
									alt=""
									src={`${SpotifyLogo}`}
								></img>
							</div>
							<div className="bottom-section__info-container">
								<div className="song-container">
									<p style={{
										backgroundImage: `linear-gradient(45deg, ${primaryColor}, #CFD7C7)` 
									}}>{result.name}</p>
								</div>
								<div className="artist-container">
									<p>
										{result.artists.map((artist, i) => {
											return i === result.artists.length - 1
												? `${artist.name}`
												: `${artist.name}, `;
										})}
									</p>
								</div>
							</div>
						</div>
					</div>
				);
			})}
		</div>
	);
};

export default SongCards;
import ReactTooltip from "react-tooltip";
import "./ToolTip.scss";

type ToolTipBottomProps = {
    message: string,
    id: string
}

const ToolTipBottom = (props: ToolTipBottomProps) => {

	return (
		<ReactTooltip 
			arrowColor="transparent" 
			id={props.id} 
			place="bottom" 
			effect="solid"
			className="tool-tip__bottom"
		>
			{props.message}
		</ReactTooltip>
	);
};

export default ToolTipBottom;